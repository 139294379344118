<template>
  <h1 class="text-center primary--text">
    {{ time }}
  </h1>
</template>

<script>
export default {
  data() {
    return {
      time: ''
    }
  },
  created() {
    this.updateTime();
    const now = new Date();
    const seconds = now.getSeconds();
    const millisecondsUntilNextMinute = (60 - seconds) * 1000;

    setTimeout(() => {
      this.updateTime();
      setInterval(this.updateTime, 60000);
    }, millisecondsUntilNextMinute);
  },
  methods: {
    updateTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      this.time = `${hours}:${minutes}`;
    }
  },
  beforeDestroy() {
    clearTimeout(this.initialTimeout);
    clearInterval(this.timeInterval);
  },
}
</script>

<style scoped>
.v-card-title {
  font-size: 2em;
}
</style>